export default {
  id: '2-37-sfk',
  category: ['home_decor'],

  name: {
    de: ' Pilze',
    en: ' Mushrooms',
  },
  subtitle: {
    de: 'B14 x H20 x T14 cm',
    en: 'W14 x H20 x D14 cm',
  },
  meta_title: {
    de: 'Du Glückspilz: Pilze basteln aus Papier',
    en: 'Lucky you: Papercraft mushrooms',
  },
  meta_description_google: {
    de: 'Mit unserer Vorlage für Papier Pilze bastelst du dir eine moderne low poly Herbstdeko für dein Zuhause. ',
    en: 'Create a modern fall decoration for your home with our template for a paper mushroom.',
  },
  meta_description_social: {
    de: '',
    en: '',
  },

  description: {
    de: `Du Glückspilz! Fliegenpilze basteln ist nicht nur eines der schönsten DIY Beschäftigungen im Herbst, sie sind auch gleichzeitig einer der beliebtesten Glückssymbole. 
    
    Diese kleinen magischen Pilze aus Märchenbüchern sind eine schöne Kindheitserinnerung und mit unserer Vorlage kannst du sie dir ins Hier und Jetzt basteln.
   
    Kuschelt euch daheim ein und gestaltet die Papierpilze mit leuchtend roten Kappen oder in ganz natürlichen Brauntönen. Dank des Low Poly Aussehens unserer Pilze kannst du die Papierflächen auch bestempeln. Gestalte deine ganz persönliche Herbstdeko und lass dich von der Magie des Herbstes anstecken.
 
    In der Vorlage sind 3 unterschiedliche Pilze enthalten. Die Punkte des Fliegenpilzes sind als Vorlage nicht enthalten. Diese dürft ihr euch ganz nach euren Vorstellungen ausstanzen, lochen oder mit Stift und Farbe aufmalen.
   
    Was ist drin im Bastelset?    
    - 7 vorgedruckte DIN A4 Bögen Tonkarton für 3 Pilze in unterschiedlichen Größen 
    - 500 doppelseitig, stark haftende Klebepads für sauberes und schnelles Verkleben
    - Detaillierte Schritt-für-Schritt-Anleitung in Deutsch und Englisch für den kinderleichten Zusammenbau
   
    Was benötigst du sonst noch?
    Ein wenig Geduld, eine Schere, Lineal und ein Falzwerkzeug (z.B. Ahle, Buttermesser)

    Wir empfehlen das Bastelset für Kinder ab 14 Jahren.
    
    `,
    en: `You lucky mushroom! Making toadstools is not only one of the most beautiful DIY activities in fall, it is also one of the most popular symbols of luck. These little magical symbols from fairy tale books are such a lovely childhood memory. Snuggle up at home and use our template to make your very own mushrooms with bright red caps or in completely natural shades of brown. Thanks to the low poly look of our mushrooms, you can also stamp the paper surfaces. Create your own personal fall decoration and let yourself be infected by the magic of autumn
    
    What's in the craft kit?    
    - 7 pre-printed A4 sheets of cardboard for three mushrooms in 3 differenz sizes
    - 500 double-sided, strong adhesive pads for clean and quick gluing
    - Detailed step-by-step instructions in German and English for easy assembly
   
    What else do you need?
    A little patience, scissors, a ruler and a folding tool (e.g. awl, butter knife)
`,
  },

  pdf: {
    description: {
      de: `Du Glückspilz! Fliegenpilze basteln ist nicht nur eines der schönsten Beschäftigung im Herbst, er ist auch einer der beliebtesten Glückssymbole. Diese kleinen magischen Symbole aus Märchenbüchern sind eine so schöne Kindheitserinnerung. 
      
      Kuschelt euch daheim ein und bastelt mir unserer Vorlage eure ganz eigenen Pilze mit leuchtend roten Kappen oder in ganz natürlichen Brauntönen. Dank des Low Poly Aussehens unserer Pilze kannst du die Papierflächen auch bestempeln. Gestalte deine ganz persönliche Herbstdeko und lass dich von der Magie des Herbstes anstecken.
      
      In der Vorlage sind 3 unterschiedliche Pilze enthalten. Die Punkte des Fliegenpilzes sind als Vorlage nicht enthalten. Diese dürft ihr euch ganz nach euren Vorstellungen ausstanzen, lochen oder mit Stift und Farbe aufmalen.
      
      Mit der PDF-Vorlage im DIN A4 Format zum selbst Ausdrucken kannst du mit den Papierpilzen direkt loslegen. Ich empfehle dir 270/m² Tonkarton zu verwenden.
      Damit diese low poly Pilze gelingt, benötigst du 7 DIN A4 Bögen Tonkarton, einen Drucker, eine Schere und einen Kleber.

      Es handelt sich hierbei um ein digitales Produkt. Direkt nach Zahlungseingang erhältst du den Downloadlink per Email zugeschickt. Das erfolgt innerhalb von einer Stunde.`,

      en: `You lucky mushroom! Making toadstools is not only one of the best things to do in the fall, it is also one of the most popular symbols of luck. These little magical symbols from fairy tale books are such a wonderful childhood memory. Snuggle up at home and use our template to make your very own mushrooms with bright red caps or in completely natural shades of brown. Thanks to the low poly look of our mushrooms, you can also stamp the paper surfaces. Create your own personal fall decoration and let the magic of autumn infect you. The template contains 3 different mushrooms. The dots of the toadstool are not included as a template. You can punch them out, punch holes or draw them on with pen and paint as you wish. 
      
      With the PDF template in A4 or letter format to print out yourself, you can get started with your low poly mushrooms straight away. I recommend using 230g/m² cardboard if you print the template on letter size. If you scale the template in half, I recommend using 130g/m².
      To make these mushrooms, you will need 7 A4 or letter sheets of cardboard, a printer, scissors and glue.

      This is a digital product. You will receive the download link by email immediately after receipt of payment. This will be done within one hour.`,
    },
    price: 9.9,
    priceThirdCountry: 9.9,

    image: {
      filename: {
        de: 'products/pilze/pilze-basteln-papier.jpg',
        en: 'products/pilze/pilze-basteln-papier.jpg',
      },
      alt_text: {
        de: 'Papier Pilze basteln',
        en: 'Papercraft mushrooms',
      },
    },
  },

  colorinspirations: [
    {
      caption: {
        de: 'Fliegenpilze in rot und chamois',
        en: 'Toadstools in red and chamois',
      },
      image: {
        filename: {
          de: 'products/pilze/pilze-basteln-papier.jpg',
          en: 'products/pilze/pilze-basteln-papier.jpg',
        },
        alt_text: {
          de: 'Fliegenpilze aus Papier mit roten Kappen und weißem Stiel',
          en: 'Fly agaric from paper in red and white',
        },
      },
    },

    {
      caption: {
        de: 'Pilze in schokobraun und chamois',
        en: 'Mushrooms in brown and chamois',
      },
      image: {
        filename: {
          de: 'products/pilze/pilze-basteln-papier-champignons.jpg',
          en: 'products/pilze/pilze-basteln-papier-champignons.jpg',
        },
        alt_text: {
          de: 'Pilze aus Papier in schokobraun und chamois',
          en: 'Papercraft Mushrooms in brown and chamois',
        },
      },
    },

    {
      caption: {
        de: 'Pilze im Größenverhältnis mit Holzdeko',
        en: 'Size proportion of the paper mushrooms ',
      },
      image: {
        filename: {
          de: 'products/pilze/papier-pilze-herbst-deko-basteln.jpg',
          en: 'products/pilze/papier-pilze-herbst-deko-basteln.jpg',
        },
        alt_text: {
          de: 'Papierpilze dekoriert mit Holzelchen auf einem Regal',
          en: 'Papercraft mushrooms placed together with wooden mooses on a shelf',
        },
      },
    },

    {
      caption: {
        de: 'Fliegenpilze aus Papier in rot und weiß',
        en: 'Paper toadstools in red and white',
      },
      image: {
        filename: {
          de: 'products/pilze/papier-pilze-basteln.jpg',
          en: 'products/pilze/papier-pilze-basteln.jpg',
        },
        alt_text: {
          de: 'Papercraft Fliegenpilz ',
          en: 'Papercraft toadstool',
        },
      },
    },

    {
      caption: {
        de: 'Vorschau auf die Bastelvorlage',
        en: 'Preview of template',
      },
      image: {
        filename: {
          de: 'products/pilze/pilze-basteln-vorlage-vorschau.jpg',
          en: 'products/pilze/pilze-basteln-vorlage-vorschau.jpg',
        },
        alt_text: {
          de: '3D Papercraft Pilze Bastelvorlage Vorschau PDF',
          en: '3D Papercraft Mushrooms Craft Template Preview PDF',
        },
      },
    },
  ],

  choose: {
    image: {
      filename: {
        de: 'products/pilze/pilze-basteln-papier.jpg',
        en: 'products/pilze/pilze-basteln-papier.jpg',
      },
      alt_text: {
        de: 'Papercraft Pilze von PaperShape',
        en: 'Papercraft mushrooms from PaperShape',
      },
    },
    colors: {
      color1: {
        de: 'Pilzhut',
        en: 'Mushroom hat',
      },

      color2: {
        de: 'Stiel & Unterseite',
        en: 'Stem and mushroom bottom',
      },
    },
    disableColors: {
      color1: ['schwarz', 'bunt'],
      color2: ['schwarz', 'bunt'],
    },
    price: 19.9,
    priceThirdCountry: 19.9,
  },
};
