export default {
  id: '2-33-sfk',
  category: ['home_decor'],

  name: {
    de: ' Dalapferd ',
    en: ' Dala horse',
  },
  subtitle: {
    de: 'B14 x H15 x T5 cm',
    en: 'W14 x H15 x D5 cm',
  },
  meta_title: {
    de: 'Modernes Dalapferd aus Papier basteln',
    en: 'How to make a modern 3d papercraft dala horse',
  },
  meta_description_google: {
    de: 'Bastel dir mit unserer Dalapferd Vorlage ein modernes schwedisches Designobjekt für dein Zuhause.',
    en: 'Create a modern Swedish design object for your home with our papercraft Dala horse template.',
  },
  meta_description_social: {
    de: '',
    en: '',
  },

  description: {
    de: `Jihaaa! Galoppiere mit unserem Papercraft Dalapferd in die Welt des schwedischen Designs. Dank des Low Poly Aussehens unseres Pferdchens kannst du die kleinen Papierflächen mit schönen Mustern bemalen, bestempeln oder in ihrer Unifarbe belassen.
      Noch heute werden die "Dalahäst" in dem idyllischen Dorf Nusnäs in Handarbeit gefertigt. Wenn du also nicht so weit reisen magst, probier dich an unserer Vorlage aus und gestalte dein ganz persönliches Dalapferd zum Dekorieren oder Verschenken. In Schweden ist es ein traditionell weihnachtliches Dekoelement.
    
    Was ist drin im Bastelset?    
    - 8 vorgedruckte DIN A4 Bögen Tonkarton für 4 Dalapferde 
    - 500 doppelseitig, stark haftende Klebepads für sauberes und schnelles Verkleben
    - Detaillierte Schritt-für-Schritt-Anleitung in Deutsch und Englisch für den kinderleichten Zusammenbau
   
    Was benötigst du sonst noch?
    Ein wenig Geduld, eine Schere, Lineal und ein Falzwerkzeug (z.B. Ahle, Buttermesser)

    Wir empfehlen das Bastelset für Kinder ab 14 Jahren.
    
    `,
    en: '',
  },

  pdf: {
    description: {
      de: `Jihaaa! Galoppiere mit unserem Dalapferd in die Welt des schwedischen Designs. Dank des Low Poly Aussehens unseres Pferdchens kannst du die kleinen Papierflächen mit schönen Mustern bemalen, bestempeln oder in ihrer Unifarbe belassen.
      Noch heute werden die "Dalahäst" in dem idyllischen Dorf Nusnäs in Handarbeit gefertigt. Wenn du also nicht so weit reisen magst, probier dich an unserer Vorlage aus und gestalte dein ganz persönliches Dalapferd zum Dekorieren oder Verschenken. In Schweden ist es ein traditionell weihnachtliches Dekoelement. 
      
      Mit der PDF-Vorlage im DIN A4 Format zum selbst Ausdrucken kannst du mit deinem tierischen Kunstobjekt direkt loslegen. Ich empfehle dir 230g/m² Tonkarton zu verwenden.
    Damit dieses low poly Dalapferd gelingt, benötigst du 2 DIN A4 Bögen Tonkarton, einen Drucker, eine Schere und einen Kleber.

      Es handelt sich hierbei um ein digitales Produkt. Direkt nach Zahlungseingang erhältst du den Downloadlink per Email zugeschickt. Das erfolgt innerhalb von einer Stunde.`,

      en: ``,
    },
    price: 7.9,
    priceThirdCountry: 7.9,

    image: {
      filename: {
        de: 'products/dalapferd/dalapferd-3d-basteln-papier.jpg',
        en: '',
      },
      alt_text: {
        de: 'Zwei blaue Dalapferde',
        en: '',
      },
    },
  },

  colorinspirations: [
    {
      caption: {
        de: 'Dalapferde in Blautönen',
        en: '',
      },
      image: {
        filename: {
          de: 'products/dalapferd/dalapferd-3d-basteln-papier.jpg',
          en: '',
        },
        alt_text: {
          de: 'Dalapferde in Blautönen ',
          en: '',
        },
      },
    },

    {
      caption: {
        de: 'Bemaltes Dalapferd',
        en: '',
      },
      image: {
        filename: {
          de: 'products/dalapferd/3d-dalapferd-gemustert.jpg',
          en: '',
        },
        alt_text: {
          de: 'Beispiel für bemaltes Dalapferd',
          en: '',
        },
      },
    },
    {
      caption: {
        de: 'Rotes Dalapferd - Größenverhältnis',
        en: '',
      },
      image: {
        filename: {
          de: 'products/dalapferd/modernes-dalapferd-papier-diy.jpg',
          en: '',
        },
        alt_text: {
          de: 'Rotes Papier low poly Dalapferd vor grüner Wand',
          en: '',
        },
      },
    },

    {
      caption: {
        de: 'Vorschau auf die Bastelvorlage',
        en: '',
      },
      image: {
        filename: {
          de: 'products/dalapferd/dalapferd-bastel-vorlage-pdf.jpg',
          en: '',
        },
        alt_text: {
          de: '3D Dalapferd Bastelvorlage Vorschau PDF',
          en: '',
        },
      },
    },
  ],

  choose: {
    image: {
      filename: {
        de: 'products/dalapferd/dalapferd-3d-basteln-papier.jpg',
        en: 'products/dalapferd/dalapferd-3d-basteln-papier.jpg',
      },
      alt_text: {
        de: 'Selbstgebastelte Dalapferde aus Papier in zwei Blautönen',
        en: '',
      },
    },
    colors: {
      color1: {
        de: 'Dalapferd 1+2',
        en: 'Dala horse 1+2',
      },

      color2: {
        de: 'Dalapferd 3+4',
        en: 'Dala horse 3+4',
      },
    },
    disableColors: {
      color1: ['schwarz', 'bunt'],
      color2: ['schwarz', 'bunt'],
    },
    price: 19.9,
    priceThirdCountry: 19.9,
  },
};
