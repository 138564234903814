export default {
  id: '2-36-sfk',
  category: ['home_decor'],

  name: {
    de: ' Maneki Neko Katze',
    en: ' Maneki Neko Lucky Cat',
  },
  subtitle: {
    de: 'B16 x H23 x T12 cm',
    en: 'W16 x H23 x D12 cm',
  },
  meta_title: {
    de: 'So viel Glück: Maneki Neko Katze aus Papier basteln',
    en: 'How to make a 3d papercraft Maneki Neko Lucky Cat',
  },
  meta_description_google: {
    de: 'Bastel dir mit unserer Vorlage für eine Maneki Neko Katze einen modernen japanischen Glücksbringer für dein Zuhause.',
    en: 'Make a modern Japanese lucky charm for your home with our template for a Maneki Neko lucky cat.',
  },
  meta_description_social: {
    de: '',
    en: '',
  },

  description: {
    de: `Wink dir das Glück mit unserer Maneki Neko Katze herbei! Die Lucky Cat aus Papier ist ein echter Glücksbringer für eure vier Wände und bedeutet übersetzt „winkende Katze“. 
    
    Besonders beliebt ist sie als Souvenir aus Japan, China und Thailand. Sie soll der Legende nach Glück und finanziellen Reichtum herbeiwinken. Wenn du aber nicht so weit reisen magst, probier dich an unserer Vorlage aus und gestalte deine ganz persönliche Maneki Neko zum Dekorieren oder Verschenken. 

    Wenn du eine Winkekatze kaufen möchtest, achte auf die Farbe. Ihre Bedeutung ist nämlich sehr unterschiedlich. Entscheidest du dich für eine grüne Winkekatze steht sie für Erfolg in der Bildung, in pink oder rosa winkt sie euch eine glückliche Liebe zu, eine rote Lucky Cat schützt euch vor Bösem und Krankheiten, eine weiße Katze steht für positive Ereignisse und in gold ist sie ein Symbol für Wohlstand. Wem die Farbglauberei zu viel ist, der passt die Farbe einfach an seine Deko daheim an.
  
   
    Was ist drin im Bastelset?    
    - 6 vorgedruckte DIN A4 Bögen Tonkarton für eine Maneki Neko Katze 
    - 500 doppelseitig, stark haftende Klebepads für sauberes und schnelles Verkleben
    - Detaillierte Schritt-für-Schritt-Anleitung in Deutsch und Englisch für den kinderleichten Zusammenbau
   
    Was benötigst du sonst noch?
    Ein wenig Geduld, eine Schere, Lineal und ein Falzwerkzeug (z.B. Ahle, Buttermesser)

    Wir empfehlen das Bastelset für Kinder ab 14 Jahren.
    
    `,
    en: `
`,
  },

  pdf: {
    description: {
      de: `Wink dir das Glück mit unserer Maneki Neko Katze herbei! Die Lucky Cat aus Papier ist ein echter Glücksbringer für eure vier Wände und bedeutet übersetzt „winkende Katze“. 
    
    Besonders beliebt ist sie als Souvenir aus Japan, China und Thailand. Sie soll der Legende nach Glück und finanziellen Reichtum herbeiwinken. Wenn du aber nicht so weit reisen magst, probier dich an unserer Vorlage aus und gestalte deine ganz persönliche Maneki Neko zum Dekorieren oder Verschenken. 

    Wenn du eine Winkekatze kaufen möchtest, achte auf die Farbe. Ihre Bedeutung ist nämlich sehr unterschiedlich. Entscheidest du dich für eine grüne Winkekatze steht sie für Erfolg in der Bildung, in pink oder rosa winkt sie euch eine glückliche Liebe zu, eine rote Lucky Cat schützt euch vor Bösem und Krankheiten, eine weiße Katze steht für positive Ereignisse und in gold ist sie ein Symbol für Wohlstand. Wem die Farbglauberei zu viel ist, der passt die Farbe einfach an seine Deko daheim an.

      Mit der PDF-Vorlage im DIN A4 Format zum selbst Ausdrucken kannst du mit deinem Glücksbringer direkt loslegen. Ich empfehle dir 230g/m² Tonkarton zu verwenden, wenn du die Vorlage auf DIN A4 ausdruckst. Wenn du die Vorlage auf die Hälfte skalierst, dann rate ich dir zu 130g/m².
      Damit diese low poly Maneki Neko Katze gelingt, benötigst du 6 DIN A4 Bögen Tonkarton, einen Drucker, eine Schere und einen Kleber.

      Es handelt sich hierbei um ein digitales Produkt. Direkt nach Zahlungseingang erhältst du den Downloadlink per Email zugeschickt. Das erfolgt innerhalb von einer Stunde.`,

      en: ``,
    },
    price: 9.9,
    priceThirdCountry: 9.9,

    image: {
      filename: {
        de: 'products/manekineko/maneki-neko-katze-lucky-cat-basteln.jpg',
        en: 'products/manekineko/maneki-neko-katze-lucky-cat-basteln.jpg',
      },
      alt_text: {
        de: 'Maneki Neko Katze aus Papier in gold und rot',
        en: '',
      },
    },
  },

  colorinspirations: [
    {
      caption: {
        de: 'Winkekatze klassisch in gold und rot',
        en: '',
      },
      image: {
        filename: {
          de: 'products/manekineko/maneki-neko-katze-lucky-cat-basteln.jpg',
          en: '',
        },
        alt_text: {
          de: 'Braune 3D Katze aus Papier',
          en: '',
        },
      },
    },

    {
      caption: {
        de: 'Einfarbige Winkekatze in helllila',
        en: '',
      },
      image: {
        filename: {
          de: 'products/manekineko/winkekatze-basteln.jpg',
          en: '',
        },
        alt_text: {
          de: 'Papier Winkekatze in low poly in helllila vor grüner Wand',
          en: '',
        },
      },
    },

    {
      caption: {
        de: 'Maneki Neko in perlweiß und orange',
        en: ' ',
      },
      image: {
        filename: {
          de: 'products/manekineko/maneki-neko-katze-basteln.jpg',
          en: '',
        },
        alt_text: {
          de: 'Winkekatze Maneki Neko aus Papier in orange und perlweiß',
          en: '',
        },
      },
    },

    {
      caption: {
        de: 'Vorschau auf die Bastelvorlage',
        en: '',
      },
      image: {
        filename: {
          de: 'products/manekineko/winkekatze-vorlage-basteln-vorschau.jpg',
          en: '',
        },
        alt_text: {
          de: '3D Papercraft Maneki Neko Katze Bastelvorlage Vorschau PDF',
          en: '',
        },
      },
    },
  ],

  choose: {
    image: {
      filename: {
        de: 'products/manekineko/maneki-neko-katze-lucky-cat-basteln.jpg',
        en: '',
      },
      alt_text: {
        de: 'Papercraft Maneki Neko Katze von PaperShape',
        en: '',
      },
    },
    colors: {
      color1: {
        de: 'Maneki Neko Katze',
        en: 'Maneki Neko Cat',
      },

      color2: {
        de: 'Ohren & Halsband',
        en: 'Ears & Collar',
      },
    },
    disableColors: {
      color1: ['schwarz'],
      color2: ['schwarz'],
    },
    price: 19.9,
    priceThirdCountry: 19.9,
  },
};
