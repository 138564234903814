export default {
  id: '3-08-blog',
  category: ['home_decor'],

  name: {
    de: ' Eicheln ',
    en: ' Acorns',
  },
  subtitle: {
    de: 'B10 x H15 x T10 cm',
    en: 'W10 x H15 x D10 cm',
  },
  meta_title: {
    de: '3D Papier Eicheln zum Basteln',
    en: '3D Paper Craft Acorns',
  },
  meta_description_google: {
    de: 'Mit dieser Vorlage für herbstliche Eicheln kannst du etliche kreative Ideen umsetzen: als Tischdeko, als Kranzasseccoire...',
    en: 'With this template for autumn acorns you can implement several creative ideas: as a table decoration, as a wreath accessory...',
  },
  meta_description_social: {
    de: '',
    en: '',
  },

  pdf: {
    description: {
      de: `Mit dieser digitalen Vorlage für herbstliche Eicheln kannst du etliche kreative Ideen umsetzen. <a href="/de/blog/3d-eicheln-basteln/">In diesem Blogpost</a> zeige ich dir, wie einfach das geht. 
      
      Mit der PDF-Vorlage im DIN A4 Format zum selbst Ausdrucken kannst du direkt loslegen. Ich empfehle dir 130g/m² Tonpapier zu verwenden.
      Damit die Papiereicheln gelingen, benötigst du einen Drucker, 2 Bögen Tonpapier, eine Schere und einen Kleber.

      Es handelt sich hierbei um ein digitales Produkt. Direkt nach Zahlungseingang erhältst du den Downloadlink per Email zugeschickt. Das erfolgt innerhalb von einer Stunde.`,

      en: `With this template for autumn acorns you can implement several creative ideas <a href="/en/blog/3d-eicheln-basteln/">In this blogpost</a> I show you how easy it is.
      
      With the PDF template in A4 format for self-printing you can get started right away. I recommend you to use 130g/m² paper.
      To make the daisies succeed, you'll need a printer, 2 sheets of paper, scissors and glue.

      This is a digital product. Immediately after receipt of payment you will get the download link via email. This takes place within an hour.`,
    },
    price: 6.9,
    priceThirdCountry: 6.9,
    image: {
      filename: {
        de: 'products/eichelnshop/papercraft-eicheln-basteln.jpg',
        en: 'products/eichelnshop/papercraft-eicheln-basteln.jpg',
      },
      alt_text: {
        de: 'Drei selbst gebastelte Eicheln aus Papier ',
        en: 'Three papercrafted acorns ',
      },
    },
  },
};
