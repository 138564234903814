export default {
  id: '3-02-blog',
  category: ['home_decor'],

  name: {
    de: 'Windlicht Herz',
    en: 'Heart lantern',
  },
  subtitle: {
    de: 'B16 x H14 x T10 cm',
    en: 'W16 x H14 x D10 cm',
  },
  meta_title: {
    de: 'Romantisches Herz Windlicht ',
    en: 'Romantic heart lantern',
  },
  meta_description_google: {
    de: 'Mit dieser Vorlage für ein romantisches Windlicht in Herzform verzauberst du das Ambient auf deinem Balkon oder deine Hochzeitstischdeko.',
    en: 'With this template for a romantic lantern in the shape of a heart you will enchant the ambience on your balcony or your wedding table decoration.',
  },
  meta_description_social: {
    de: '',
    en: '',
  },

  pdf: {
    description: {
      de: `Mit dieser digitalen Vorlage für ein romantisches Windlicht kannst du ein schönes Ambiente kreieren. <a href="/de/blog/windlicht-basteln-herz/">In diesem Blogpost</a> zeige ich dir, wie einfach das geht. 
      
      Mit der PDF-Vorlage im DIN A4 Format zum selbst Ausdrucken kannst du direkt loslegen. Ich empfehle dir 120g/m² Transparentpapier zu verwenden.
      Damit das Windlicht gelingt, benötigst du einen Drucker, 3 Bögen Tonpapier, eine Schere und einen transparenten Kleber.

      Es handelt sich hierbei um ein digitales Produkt. Direkt nach Zahlungseingang erhältst du den Downloadlink per Email zugeschickt. Das erfolgt innerhalb von einer Stunde.`,

      en: `With this digital template for a romantic lantern you can create a beautiful ambience. <a href="/en/blog/windlicht-basteln-herz/">In this blogpost</a> I show you how easy it is.
      
      With the PDF template in A4 format for self-printing you can get started right away. I recommend you to use 120g/m² transluscent paper.
      To make the daisies succeed, you'll need a printer, 3 sheets of paper, scissors and transparent glue.

      This is a digital product. Immediately after receipt of payment you will get the download link via email. This takes place within an hour.`,
    },
    price: 9.9,
    priceThirdCountry: 9.9,
    image: {
      filename: {
        de: 'products/windlichtherz/windlicht-basteln-herz.jpg',
        en: 'products/windlichtherz/windlicht-basteln-herz.jpg',
      },
      alt_text: {
        de: 'Selbstgebasteltes transparentes Windlicht in Herzform ',
        en: 'Papercrafted heart lantern',
      },
    },
  },
};
