export default {
  url: '3d-eicheln-basteln',
  countermark: 'vg07.met.vgwort.de/na/e3fd0234d5944e4f907b88babacd69b7',
  imageFolder: 'eichel2',

  name: {
    de: '3d Eicheln aus Papier',
    en: 'Paper acorns',
  },
  id: '3-08-blog',
  pdf: {
    price: 6.9,
    priceThirdCountry: 6.9,
    image: {
      filename: {
        de: 'content/eichel2/papercraft-eicheln-basteln.jpg',
        en: 'content/eichel2/papercraft-eicheln-basteln.jpg',
      },
      alt_text: {
        de: '3d Eicheln aus Papier basteln als Herbstdeko #papershape #bastelnmitpapier #diy #herbstdeko #eicheln',
        en: 'How to make 3d acorns from paper #papercraft #crafting #diy',
      },
    },
  },

  meta_title: {
    de: '3D Eicheln basteln aus Papier (auch als Geschenkbox) ',
    en: 'How to make 3d acorns from paper (and use it as gift box)',
  },
  meta_description_google: {
    de: 'Papershape zeigt dir, wie du 3d Eicheln bastelst aus Papier und diese auch als Geschenkbox verwenden kannst.',
    en: 'With this template you will be able to make lots of paper acorns for your fall decor or even use them as a gift box.',
  },
  meta_description_social: {
    de: 'Papershape zeigt dir, wie du 3d Eicheln bastelst aus Papier und diese auch als Geschenkbox verwenden kannst.',
    en: 'With this template you will be able to make lots of paper acorns for your fall decor or even use them as a gift box.',
  },
  meta_images_social: [
    {
      de: 'papercraft-eicheln-basteln.jpg',
      en: 'papercraft-eicheln-basteln.jpg',
    },
  ],
  image_main: {
    filename: {
      de: 'papercraft-eicheln-basteln.jpg',
      en: 'papercraft-eicheln-basteln.jpg',
    },
    alt_text: {
      de: '3d Eicheln aus Papier basteln als Herbstdeko #papershape #bastelnmitpapier #diy #herbstdeko #eicheln',
      en: 'How to make 3d acorns from paper #papercraft #crafting #diy',
    },
  },
  h1: {
    de: '3D Eicheln basteln aus Papier (auch als Geschenkbox) ',
    en: 'How to make 3d acorns from paper (and use it as a gift box)',
  },
  description: {
    de: `
      <p>Für die wohnliche Herbstdeko dürfen Eicheln zum kühlen Herbst einfach nicht fehlen. </p>
      <p>Heute zeige ich euch in diesem DIY-Post, wie ihr in wenigen Schritten wirklich schöne 3d Eicheln ganz einfach selber
       basteln könnt.  </p>
      <p>Von der Größe her sind sie so handlich, dass man den Kopf der Eichel abnehmen kann und Kleinigkeiten
      als Geschenk verstecken kann. Ihr kennt ja meine Vorliebe Deko und Funktion zu kombinieren. So wird aus der Herbstdeko auch gleich noch eine schöne Geschenkverpackung. 
      
     <p> Ihr könnt die Eicheln in zwei Farben gestalten, weil die Vorlage sowohl den unteren als auch oberen Teil einzeln abdeckt. </p>
     Eine etwas andere Variante von Eicheln findet ihr <a href="/de/blog/eicheln-papier-herbstdeko">hier</a></p>
      <p>Größe: HBT 15 x 10 x 10 cm</p>
      
    
    `,
    en: `
    <p> Acorns for the fall season can not be missed for the homely autumn décor. </ P>
    <p> Today I'll show you how to make really beautiful 3d acorns in just a few steps. </ P>
    <p> In terms of size, they are so handy that you can remove the head of the acorn and put some sweets into it.
  Thus, the autumn decoration also becomes a nice gift box.
    
   <p> You can make the acorns in two colors because the template covers each of the lower and upper parts individually. </ P>
   For a slightly different version of acorns, see <a href="/en/blog/eicheln-papier-herbstdeko"> here </a> </p>
    Size: HBW 15 x 10 x 10 cm </ p>
    `,
  },
  inspirationTitle: {
    de: '',
    en: '',
  },
  inspirations: [
    {
      image: {
        filename: {
          de: '',
          en: '',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
    },
    {
      text: {
        de: ``,
        en: ``,
      },
    },
    {
      image: {
        filename: {
          de: '',
          en: '',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
    },
    {
      text: {
        de: ``,
        en: ``,
      },
    },

    {
      image: {
        filename: {
          de: '',
          en: '',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
    },
    {
      text: {
        de: ``,
        en: ``,
      },
    },

    {
      image: {
        filename: {
          de: '',
          en: '',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
    },
    {
      text: {
        de: ``,
        en: ``,
      },
    },
  ],

  stepsTitle: {
    de: '3D Origami Eicheln basteln: Anleitung',
    en: 'Step by step: how to make origami acorns',
  },
  steps: [
    {
      image: {
        filename: {
          de: '',
          en: '',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      video: '',
      text: {
        de: `Ihr benötigt pro Eichel zwei DINA4 Papierseiten. Verwendet am besten Tonpapier mit einer Grammatur von bis zu 230g/m².
        Druckt euch die Vorlage aus und schneidet nun alle durchgezogenen Linien mit einer
        Schere aus.
       
        `,
        en: `You need 2 pages per acorn. 
          The best will be you use paper with a grammage of up to 230g/m². 
        Print out the template and cut out all solid lines with scissors.`,
      },
    },
    {
      image: {
        filename: {
          de: '',
          en: '',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      video: '',
      text: {
        de: `Falzt mit einer Ahle oder einem Falzbein und einem Lineal alle gestrichelten Linien (egal ob lang oder kurz gestrichelt) vor. Dazu das Lineal an die Linie anlegen und mit der Ahle mit Druck
        nachfahren.`,
        en: `Crease all dashed lines with an awl or a bonefolder. Therefore, place a ruler next to the dashed lines
        and crease the lines by pressing the bonefolder down. 
      `,
      },
    },
    {
      image: {
        filename: {
          de: '',
          en: '',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      video: '',
      text: {
        de: ` Faltet die lang gestrichelten Linien von euch weg, d.h. nach unten. Faltet die kurz gestrichelten Linien nach oben, d.h. zu euch hin.
        `,
        en: `Fold the long dashed lines away from you, i.e. downward. 
        Fold the short dashed lines up, i.e. up to you.`,
      },
    },

    {
      image: {
        filename: {
          de: '',
          en: '',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      video: '',
      text: {
        de: `Klebt auf die Laschen der einzelnen Papierteile Klebepads. Wichtig ist, dass ihr das Pad auf die schöne Seite und damit die unbedruckte Seite der Lasche klebt. 
        Ihr beginnt in nummerischer Reihenfolge anschließend die einzelnen Teile zu verkleben, d.h. ihr startet bei 1 auf A und verklebt diese. Danach verklebt ihr Nr. 2 und so weiter. Eine Zahl existiert immer doppelt, sodass die Zahlenpaare
        immer verklebt werden müssen. Zum Schluss stülpt ihr den Deckel der Eicheln über den unteren Teil und ready! 
        Nun wünsche ich euch viel Spaß mit eurer DIY Herbstdeko.
  `,
        en: `Use double-sided tape or any glue onto the unprinted side of the flaps.
        I prefer to craft with adhesive tape because your fingers stay clean. Each number exists twice. Stick all the same numbers together in ascending order (1, 2, 3, ...). Start with section 1 and stick it to the section marked 1 on paper A, pressing firmly enough to make them stick together.
        Finally you put the top of the acorn above the bottom and you are done!`,
      },
    },
  ],

  materials: [
    {
      de: 'Vorlage herunterladen (siehe unten)',
      en: 'Donwload template (scroll down)',
    },
    {
      de: '2 DINA4 Tonkarton oder Tonpapier (bis zu 230g/m²)',
      en: '2 colored papers (8.5in x 11in) (weight: 65lb-110lb)',
    },

    {
      de: 'Bastelkleber oder doppelseitige Klebepads',
      en: 'Glue or double-sided tape',
    },
    {
      de: 'Schere',
      en: 'Scissors',
    },
    {
      de: 'Lineal',
      en: 'Ruler',
    },
    {
      de: 'Ahle / Falzbein / Buttermesser / defekte Kugelschreibermine',
      en: 'Bonefolder / awl / ball pen refill etc.',
    },
    {
      de: 'ggf. Schneidematte als Unterlage',
      en: 'Cutting mat',
    },
  ],
  pinterest: {
    title: {
      de: 'Speichert diese DIY Idee auf Pinterest für euer nächstes Projekt',
      en: 'Pin them!',
    },
    images: [
      {
        filename: {
          de: '',
          en: '',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
    ],
  },
};
