export default {
  url: '3d-kuerbis-basteln',
  countermark: 'vg07.met.vgwort.de/na/cd427fd111a14dc3a02bdb60e7a32d58',
  imageFolder: 'pumpkin',

  name: {
    de: 'DIY Kürbis',
    en: 'Papercraft pumpkin',
  },
  id: '3-05-blog',
  pdf: {
    price: 9.9,
    priceThirdCountry: 9.9,
    image: {
      filename: {
        de: 'content/pumpkin/kuerbis-basteln.jpg',
        en: 'content/pumpkin/kuerbis-basteln.jpg',
      },
      alt_text: {
        de: 'DIY Herbstdeko Kürbis basteln aus Papier #bastelnmitpapier #papercraft #diy',
        en: 'Learn how to make a papercraft pumpkin #papercraft #diy #pumpkin',
      },
    },
  },

  meta_title: {
    de: 'Moderner DIY Kürbis aus Papier basteln ',
    en: 'How do I make a papercraft pumpkin',
  },
  meta_description_google: {
    de: 'Dieser 3D Papier Kürbis macht sich als Herbstdeko, als stimmungsvolle Lampe oder für Halloween super. Mit der Vorlage von papershape kannst du ihn jetzt nachbasteln.',
    en: 'Download the template for this lovely 3d paper pumpkin and use it as fall decor, a lamp or for halloween. Super easy to make and fun.',
  },
  meta_description_social: {
    de: 'DIY 3D Papier Kürbis als Herbstdeko, Lampe oder für Halloween basteln - Vorlage #papershape',
    en: 'fall decor craft: 3d paper pumpkin with  template  #papershape ',
  },
  meta_images_social: [
    {
      de: 'kuerbis-basteln.jpg',
      en: '3d-paper-pumpkin-fall-craft.jpg',
    },
  ],
  image_main: {
    filename: {
      de: 'kuerbis-basteln.jpg',
      en: '3d-paper-pumpkin-fall-craft.jpg',
    },
    alt_text: {
      de: 'Kürbis basteln aus Papier als Deko zum Herbst für den Tisch oder Eingang - #papershape ',
      en: 'fall diy: step by step 3d paper pumpkin craft for adults ',
    },
  },
  h1: {
    de: 'Moderner DIY Kürbis aus Papier (Basteln mit Vorlage)',
    en: 'How do I make a modern 3d pumpkin from paper (template)',
  },
  description: {
    de: `
   <p> Kürbisse sind nur etwas für Halloween?</p>
     <p>Mir fällt da noch so vieles mehr an Gelegenheiten ein: z.B. zum Erntedank basteln, Kürbisse als Lampe einsetzen oder auf dem Tisch als Herbstdeko.  </p>
     <p>Mit diesem Papier Deko Kürbis in groß und der Vorlagen sollte das kein Problem für euch sein.</p>
     <p>Zugegeben, dieses Herbst DIY 
     benötigt ein wenig eurer meditativen Zeit um in seiner Pracht zu erscheinen, aber es ist die ultimative Herbstdeko. Je nachdem, was euer Drucker so kann, könnt ihr auf jeden Fall
     zwei unterschiedliche Größen an Kürbissen basteln. Und falls ihr noch Lust habt, weiter kreativ zu werden, schaut euch mal hier mein Pinterest Board an, wie man <a href="https://www.pinterest.de/papershape_/k%C3%BCrbis-deko-basteln/" target="_blank">Kürbisse dekorieren</a> kann - das ist mega cool und bei Papierkürbissen habt ihr einmal den Spaß 
     und könnt ihn jedes Jahr wieder rausholen.</p>
    <p> Passend zu eurer Kübis Deko solltet ihr euch unbedingt diese <a href="/de/blog/eicheln-papier-herbstdeko/">süßen Eicheln aus Papier</a> nachbasteln.
      </p>
     
     <p>Der Dank für diesen Kreativstupser geht übrigens auf Andrea vom ARD-Buffett zurück - merci!</p>
     <!--<p>Und solltet ihr immer noch nicht genug vom Basteln haben, hätte ich <a href="https://www.youtube.com/watch?v=JVCdPlx73bQ">hier 5 Papier DIY Ideen für eure Herbstdeko</a>.<p>-->
    `,
    en: `
      <p>
      Pumpkins are not just for halloween:
      </p>
      
      <p>   
      Try this super cool paper craft for your fall decor or put a led-lamp underneath the pumpkin and use it as a lamp. 
      It might take you some time to paper craft the pumpkin but you will adore the end result.   
      </p>
          <p>
     And if you want to go ahead with paper diys for fall, then try these <a href="/en/blog/eicheln-papier-herbstdeko/">adorable paper acorns.</a> Also I created a pinterest-board with 
     <a href="https://www.pinterest.de/papershape_/k%C3%BCrbis-deko-basteln/" target="_blank">lovely ideas how to decorate pumpkins</a>.
      </p>
    
    `,
  },
  inspirationTitle: {
    de: '',
    en: '',
  },
  inspirations: [
    {
      image: {
        filename: {
          de: '3d-papier-kuerbis-basteln.jpg',
          en: '3d-paper-pumpkin.jpg',
        },
        alt_text: {
          de: 'Kürbis basteln aus Papier als Deko zum Herbst für den Tisch oder Eingang - #papershape ',
          en: 'fall diy: 3d paper pumpkin craft for adults',
        },
      },
    },
    {
      text: {
        de: '',
        en: '',
      },
    },
    {
      image: {
        filename: {
          de: '3d-papier-kuerbis.jpg',
          en: '3d-paper-pumpkin-craft.jpg',
        },
        alt_text: {
          de: 'Kürbis basteln aus Papier als Deko zum Herbst für den Tisch oder Eingang - #papershape ',
          en: 'fall diy: 3d paper pumpkin craft for adults  #papershape #falldecorideas',
        },
      },
    },
    {
      text: {
        de: '',
        en: '',
      },
    },
    {
      image: {
        filename: {
          de: 'papier-kuerbis-basteln.jpg',
          en: 'pumpkin-from-paper.jpg',
        },
        alt_text: {
          de: 'Kürbis basteln aus Papier als Deko zum Herbst für den Tisch oder Eingang - #papershape ',
          en: 'fall diy: 3d paper pumpkin craft for adults #papershape #falldecorideas ',
        },
      },
    },
    {
      text: {
        de: '',
        en: '',
      },
    },
  ],
  stepsBackgroundColor: 'rgb(255, 255, 255)',
  stepsTitle: {
    de: 'Anleitung: Wie bastel ich einen 3D Kürbis aus Papier',
    en: 'Instructions: How to paper craft a 3D paper pumpkin (template)',
  },
  steps: [
    {
      image: {
        filename: {
          de: '01_schneiden.jpg',
          en: '01_schneiden.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      video: '01_schneiden.mp4',
      text: {
        de: `Druckt die Kürbis Vorlage auf DIN A4 Foto- oder Tonkarton in warmen Herbsttönen aus und schneidet alle Teile entlang der durchgezogenen Linien aus. Je leichter das Papier
        von der Grammatur her ist, umso lichtdurchlässiger wird er und umso eher könnt ihr anschließend ein LED-Licht darunter stellen. Auch wird er wackeliger und damit irgendwie luftiger. 
        Je leichter das Papier, umso schwieriger aber ist es zu basteln, weil der Kürbis leichter in sich zusammenfällt.`,
        en: ``,
      },
    },
    {
      image: {
        filename: {
          de: '02_falzen.jpg',
          en: '02_falzen.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      video: '02_falzen.mp4',
      text: {
        de: `Legt ein Lineal an alle gestrichelten Linien an und fahrt mit einer Ahle entlang dieser Linien, damit eine schöne Falzkante entsteht.
       `,
        en: ``,
      },
    },

    {
      image: {
        filename: {
          de: '03_falten.jpg',
          en: '03_falten.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      video: '03_falten.mp4',
      text: {
        de: `Alle kurz gestrichelten Linien knickt ihr nach innen (zu euch hin) und alle lang gestrichelten Linien von euch weg. 
        Diese Knicke sind wie beim Origami mit Tal- und Bergfalte zu vergleichen.     `,
        en: `
       `,
      },
    },

    {
      image: {
        filename: {
          de: '04_kleben.jpg',
          en: '04_kleben.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },
      video: '04_kleben.mp4',
      text: {
        de: `Klebt auf die Laschen Klebepads oder arbeitet mit Kleber. Aber: seid sparsam mit dem Kleber, damit euch nichts überläuft. 
        Jetzt der Fun-Part: Verklebt alle Laschen in nummerischer Reihenfolge beginnend bei A und 1,2,3, etc. Jede Zahl existiert als Zahlenpaar zweimal. Und schickt mir unbedingt euer Ergebnis
        oder vertaggt es mit #3dpaperdiy.
         `,
        en: ` `,
      },
    },
  ],

  downloadName: 'kuerbis-pumpkin_papershape.pdf',
  materials: [
    {
      de: '9 DINA4 Papier in Herbsttönen (ab 120g/m²)',
      en: '9 cardstock paper in fall colors (weight: 65lb-110lb / 176gsm-300gsm)',
    },
    {
      de: 'Bastelkleber oder doppelseitige Klebepads',
      en: 'Glue or double-sided tape',
    },
    {
      de: 'Schere',
      en: 'Scissors',
    },
    {
      de: 'Lineal',
      en: 'Ruler',
    },
    {
      de: 'Ahle / Falzbein / Buttermesser / defekte Kugelschreibermine',
      en: 'Bonefolder / awl / ball pen refill etc.',
    },
    {
      de: 'ggf. Schneidematte als Unterlage',
      en: 'Cutting mat',
    },
  ],
};
